import API from "@aws-amplify/api";
import Log from "../utils/Log";

/**
 * Attempts to charge a user and change their subscription type
 * If the charge is successful they will then be rebilled via stripe.
 * @param {stripePaymentId}: string
 * @param {stripePlanId}: string
 * @param {token}: string
 * @param {totalSeats}: number
 * @param {totalCost}: number
 * @param {saleCode}: string
 */

export async function updateSubscription (
  token: string,
  productType: string,
  stripePaymentId: string,
  stripePlanId: string,
  totalSeats: number ,
  totalCost: number | null ,
  saleCode: string | null,
  name: string,
  email: string
): Promise<any> {

  try{
    const params = {
      stripePaymentId: stripePaymentId,
      stripePlanId: stripePlanId,
      totalSeats: totalSeats,
      totalCost: totalCost,
      saleCode: saleCode,
      name,
      userEmail: email
    };

    //const productType = sessionStorage.getItem("productType");

    if(productType === null){
      throw Error("productType is not defined")
    }
    console.log("updateSubscription productType", productType)
    const endpoint = getProductEndpoint(productType.toUpperCase())

    const res = await API.put("plansURL", endpoint, {
        body: params,
        headers: {
          "Content-Type": "application/json",
          Token: token,
        },
        response: true,
      })

    return res.data;

  } catch(error: any){

    if (error.response) {
      console.error("error has been hit when updating subscription", error.response.data.error)
      throw Error(error.response.data.error);
    }else {
      console.error("error has been hit when updating subscription", error)
      throw Error(error);
    }
  }

};

/**
 * Returns list of all of the subscription types and their features
 * @param {token}: string
 */

export async function getPlans(token:string):Promise<any> {
  try{
    const res = await API.get("userAccountManagementURL", "/subscriptiontypes", {
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      response: true,
    })

    return res.data
  } catch(error: any) {
    if (error.response) {
      console.error("error has been hit when getting plans", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else{
       console.error("error has been hit when getting plans", error)
      throw Error(error);
    }
  }
};

/**
 * Returns information about specific subscription plan
 * @param {subscriptionTypeId}: number
 * Not ready yet
 */

export async function getPlanInfo (token: string, subscriptionTypeId:string):Promise<any> {
  try{
    const res = await API.get("userAccountManagementURL", `subscription/${subscriptionTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      response: true,
    })

    return res.data
  }catch(error: any){
    if(error.response){
      Log.error("error has been hit when getting plan info", error.response.data.error)
      throw Error(error.response.data.error)
    } else {
      Log.error("error has been hit when getting plan info", error)
      throw Error(error)
    }
  }
};

/**
 * Returns list of all of the subscription types and their features
 * @param {token}: string
 */
export async function getUserSubscriptionProviders (token: string): Promise<bb.model.account.IUserPlanProviders>{
  try{
    const res = await API.get("plansURL", "/user/planProviders", {
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
      response: true,
    })
    
    return res.data.payload
  }catch(error:any) {
    if(error.response){
      Log.error("error has been hit when checking user plan", error.response.data.error)
      return error.response.data.error
    } else {
      // fatal error...
      Log.error("error has been hit when checking user plan", error)
      // return error
      throw error
    }
  }
};

/**
 * reNews the subscription
 * @param {stripePaymentId}: string
 * @param {stripePlanId}: string
 * @param {token}: string
 * @param {totalSeats}: number
 * @param {totalCost}: number
 * @param {saleCode}: string
 */
export async function renewSubscription (
  token: string,
  productType: string,
  stripePaymentId: string,
): Promise<any>{
  try{
    const renewPaymentBody = {
       stripePaymentId: stripePaymentId,
    }
    
    //const productType = sessionStorage.getItem("productType");

    if(productType === null){
      throw Error("productType is not defined")
    }
    const endpoint =  getProductEndpoint(productType.toUpperCase())

    const res = await API.post("plansURL", `${endpoint}/renew`, {
        body: renewPaymentBody,
        headers: {
          "Content-Type": "application/json",
          Token: token,
        },
        response: true,
      }) 

    return res.data;  

  } catch(error: any){

    if (error.response) {
      Log.error("error has been hit when renewing subscription", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when renewing subscription", error)
      throw Error(error);
    }
  }
};

/**
 * reNews the subscription
 * @param {stripePaymentId}: string
 * @param {stripePlanId}: string
 * @param {token}: string
 * @param {totalSeats}: number
 * @param {totalCost}: number
 * @param {saleCode}: string
 */
export async function accountlessUpdateSubscription (
  productType: string,
  stripePaymentId: string,
  stripePlanId: string,
  totalSeats: number,
  totalCost: number | null,
  saleCode: string | null,
  name: string,
  email: string
): Promise<any> {

  try{
    const params = {
      stripePaymentId: stripePaymentId,
      stripePlanId: stripePlanId,
      totalSeats: totalSeats,
      totalCost: totalCost,
      saleCode: saleCode,
      name,
      userEmail: email
    };

    //const productType = sessionStorage.getItem("productType");

    if(productType === null){
      throw Error("productType is not defined")
    }
    console.log("updateAccountlessSubscription productType", productType)
    const endpoint =  getProductEndpoint(productType.toUpperCase())

    const res = await API.post("plansURL", endpoint, {
        body: params,
        headers: {
          "Content-Type": "application/json",
        },
        response: true,
      })

    return res.data;  

  } catch(error: any){

    if (error.response) {
     Log.error("error has been hit when accountless subscription update", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
     Log.error("error has been hit when accountless subscription update", error)
      throw error;
    }
  }
    
};


function getProductEndpoint(productType: string): string {
  let endpoint = ""

  switch (productType) {
    case 'BB2':
      endpoint = `/users/planbb2`
      break
    case 'BB3':
      endpoint = `/users/planbb3`
      break
    case 'BB4':
      endpoint = `/users/planbb4`
      break
    case 'BBBUNDLE':
      endpoint = `/users/bundleplan`
      break
    case 'SOUNDBOX':
      endpoint = `/users/plansoundbox`
      break
    default:
      throw Error(`[stripe purchase] Unknown product type ${productType}`)
  }

  return endpoint
}


/**
 * Start a stripe checkout session
 */
export async function startStripeCheckoutSession (
  token: string|undefined,
  embedded: boolean,
  stripePriceId: string,
  quantity: number=1,
  productType: string,
  planType: string,
  parentSessionId: string|null,
  email?: string,
  couponCode?: string,
): Promise<any>{
  console.log("startStripeCheckoutSession with stripePriceId", stripePriceId, "quantity", quantity, "productType", productType, "planType", planType, "email", email, "couponCode", couponCode, "parentSessionId", parentSessionId)
  try{
    const dtoBody : any = {
      priceId: stripePriceId,
      quantity: quantity,
      productType: productType,
      planType: planType,
      email: email,
      couponCode: couponCode,
      embedded: embedded,
    }
    if(parentSessionId) {
      dtoBody.upsellFromPreviousSessionId = parentSessionId
    }
    
    let headers
    let url 
    if(token) {
      url = "/stripecheckout"
      headers = {
        "Content-Type": "application/json",
        Token: token,
      }
    }
    else {
      url = "/stripecheckoutguest"
      headers = {
        "Content-Type": "application/json",
      }
    }
    const res = await API.post("plansURL", url, {
        body: dtoBody,
        headers: headers,
        response: true,
      })

    console.log("startStripeCheckoutSession response", res.data)
    const stripeCheckoutSessionId = res.data?.payload?.id
    if(stripeCheckoutSessionId) {
      sessionStorage.setItem('stripeCheckoutSessionId', stripeCheckoutSessionId);
    }

    return res.data;  
  } catch(error: any){
    console.log("failed to start stripe checkout session ", error)
    
    if (error.response) {
      Log.error("error has been hit when starting stripe checkout session", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when starting stripe checkout session", error)
      throw Error(error);
    }
  }
};

/**
 * Retrieve a stripe checkout session; note that this endpoint nests the session object and 
 * also includes information about whether we think the user needs to complete registration
 * @param {sessionId}: string
 */
export async function retrieveStripeCheckoutSessionFromPlatform (
  sessionId: string,
): Promise<any>{
  console.log("retrieveStripeCheckoutSessionFromPlatform with sessionId", sessionId)
  try{    
    const url = "/stripecheckout/" + sessionId
    const headers = {
      "Content-Type": "application/json",
    }
    const res = await API.get("plansURL", url, {
        headers: headers,
        response: true,
      })

    console.log("retrieveStripeCheckoutSessionFromPlatform response", res.data)

    return res.data;  
  } catch(error: any){
    console.log("failed to retrieve stripe checkout session ", error)
    
    if (error.response) {
      Log.error("error has been hit when retrieving stripe checkout session", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when retrieving stripe checkout session", error)
      throw Error(error);
    }
  }
};

export async function requestStripePurchaseEmailForSessionId(
  stripeCheckoutSessionId: string
): Promise<any> {
  console.log("requestStripePurchaseEmailForSessionId with stripeCheckoutSessionId", stripeCheckoutSessionId)
  try{
    const url = "/stripecheckout/sendSessionPurchaseEmail"
    const headers = {
      "Content-Type": "application/json",
    }
    const res = await API.post("plansURL", url, {
        body: {
          stripeCheckoutSessionId: stripeCheckoutSessionId
        },
        headers: headers,
        response: true,
      })

    console.log("requestStripePurchaseEmailForSessionId response", res.data)

    return res.data;  
  } catch(error: any){
    console.log("failed to send stripe purchase email ", error)
    
    if (error.response) {
      Log.error("error has been hit when sending stripe purchase email", error.response.data.error)
      throw Error(error.response.data.error);
    }
    else {
      Log.error("error has been hit when sending stripe purchase email", error)
      throw Error(error);
    }
  }
};
