import API from "@aws-amplify/api";
import Log from "../utils/Log";
import {
  getUserAttributes,
} from "../utils/CognitoUtils";
/**
 * creates user and returns active subscription information
 * @param {token}: string
 * @param {email}: string
 * @param {referralInfo}: object, network (string) and info (string)
 */

export const createUser = async (token, referralInfo, stripeProposedBuildboxUserId, stripeOneTimeCode, stripeIsGuest) => {
  console.log("token: ", token);
  const userAttributes = await getUserAttributes(token);
  console.log("userAttributes: ", userAttributes);
  const { email } = userAttributes;  
  console.log("createUser with email (from token) ", email, "referralInfo", referralInfo, "stripeProposedBuildboxUserId", stripeProposedBuildboxUserId, "stripeOneTimeCode", stripeOneTimeCode, "stripeIsGuest", stripeIsGuest);
  return new Promise((resolve, reject) => {
    API.post("userAccountManagementURL", "/webclientLogin", {
      body: {
        referralInfo: referralInfo,
        stripeProposedBuildboxUserId: stripeProposedBuildboxUserId,
        stripeOneTimeCode: stripeOneTimeCode,
        stripeIsGuest: stripeIsGuest,
        email: email,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Token: token,
      },
      response: true, //needs this to get the whole response object
    })
      .then((res) => {
        //200 indicates a returning user

        if (res.status === 200) {
          resolve({ ...res.data, newUser: false });
        }
        //201 indicates a new registration so we need to hit GA
        else if (res.status === 201) {
          resolve({ ...res.data, newUser: true });
          //send registration event
          window.dataLayer.push({
            event: "account-creation",
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data.error);
        }
      });
  });
};

/**
 * Returns object containing information about BB client user
 * @param {token}: string
 * 
    userId: cognitoSSO.UserId,
    userName: user.CognitoUserName,
    email: email,
    clientId: cognitoSSO.ClientId,
    clientSecret: cognitoSSO.ClientSecret
    accessToken: cognitoSSO.AccessToken,
    refreshToken: cognitoSSO.RefreshToken
    username: user.CognitoUserName
 */

export const fetchTokensforCode = (token) => {
  return new Promise((resolve, reject) => {
    API.get(`userAccountManagementURL`, `/tokenLogin?token=${token}`, {
      headers: {
        "Content-Type": "application/json",
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200) {
          const ssoTokenResponse = res.data;

          Log.info(ssoTokenResponse, "got sso token response");

          resolve(ssoTokenResponse);
        }
      })
      .catch((error) => {
        Log.error(error, "error caught in fetchTokensforCode");

        reject(error);
      });
  });
};

/**
 * Generates an SSO token as an id in the DB for client retrieval
 *  * @response id: string
 */
export const storeSSOTokens = (
  clientId,
  clientSecret,
  accessToken,
  refreshToken
) => {
  return new Promise((resolve, reject) => {
    API.post(`userAccountManagementURL`, "/ssoTokens", {
      body: {
        clientId: clientId,
        clientSecret: clientSecret,
        accessToken: accessToken,
        refreshToken: refreshToken,
      },
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const ssoTokenResponse = res.data.payload;

          Log.info(ssoTokenResponse, "storeSSOTokens response");

          const { id } = ssoTokenResponse;

          resolve(id);
        }
      })
      .catch((error) => {
        Log.error(error, "error caught in storeSSOTokens");

        reject(error);
      });
  });
};

export const updateUserScreenName = (accessToken, screenName) => {
  return new Promise((resolve, reject) => {
    API.post(`bbworldUrl`, "/account/screenname", {
      body: {
        screenName: screenName,
      },
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        resolve(res.data.payload.screenName);
      })
      .catch((error) => {
        Log.error(error, "error caught getting user screen name");
        reject(error.response.data);
      });
  });
};

export const fetchUserScreenName = (accessToken) => {
  return new Promise((resolve, reject) => {
    API.get(`bbworldUrl`, "/account", {
      headers: {
        "Content-Type": "application/json",
        Token: accessToken,
      },
      response: true,
    })
      .then((res) => {
        resolve(res.data.screenName);
      })
      .catch((error) => {
        Log.error(error, "error caught getting user screen name");
        reject(error.response);
      });
  });
};
