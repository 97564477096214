import React, { lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import routes from "./constants/routes.json";

// const NewHome = lazy(() => import("./containers/home/NewHome"));
const NewBB4Home = lazy(() => import("./containers/home/NewBB4Home"));
const NewClassicStoryGamesHome = lazy(() => import("./containers/home/NewClassicStoryGamesHome"));
const BB4Product = lazy(() => import("./containers/product/BB4Product.jsx"));
const BB3Product = lazy(() => import("./containers/product/BB3Product.jsx"));
const BB2Product = lazy(() => import("./containers/product/BB2Product.jsx"));
const SoundboxProduct = lazy(() => import("./containers/product/SoundboxProduct.jsx"));
const Plans = lazy(() => import("./containers/plans/Plans"));
const NotFound = lazy(() => import("./containers/NotFound"));
const Downloads = lazy(() => import("./containers/Downloads"));
const BillingFormStripe = lazy(() => import("./containers/CheckoutStripe.tsx"));
const StripeCheckout = lazy(() => import("./containers/StripeCheckout.tsx"));
const StripeCheckoutReturn = lazy(() => import("./containers/StripeCheckoutReturn.tsx"));
const BillingFormXsolla = lazy(() => import("./containers/CheckoutXsolla.tsx"));
const AuthUI = lazy(() => import("./containers/AuthUI"));
const ConfirmAccount = lazy(() => import("./containers/ConfirmAccount"));
const ThankYou = lazy(() => import("./containers/ThankYou"));
const Login = lazy(() => import("./containers/LoginPage"));
const Loader = lazy(() => import("./containers/Loader"));
const ClientUpgradeRedirect = lazy(() =>
  import("./containers/client/ClientUpgradeRedirect")
);
const ClientLoginRedirect = lazy(() =>
  import("./containers/client/ClientLoginRedirect")
);
const ClientLogoutRedirect = lazy(() =>
  import("./containers/client/ClientLogoutRedirect")
);
const ClientPostmessageConnection = lazy(() =>
  import("./containers/client/ClientPostmessageConnection")
);
const ClientGoogleLogin = lazy(() =>
  import("./containers/client/ClientGoogleLogin")
);
const ClientFacebookLogin = lazy(() =>
  import("./containers/client/ClientFacebookLogin")
);
const ClientAppleLogin = lazy(() =>
  import("./containers/client/ClientAppleLogin")
);
const ClientDeeplinkLogin = lazy(() =>
  import("./containers/client/ClientDeeplinkLogin")
);
const ClientAwaitingFacebookLogin = lazy(() =>
  import("./containers/client/ClientAwaitingFacebookLogin")
);
const ClientAwaitingAppleLogin = lazy(() =>
  import("./containers/client/ClientAwaitingAppleLogin")
);
const ClientAwaitingGoogleLogin = lazy(() =>
  import("./containers/client/ClientAwaitingGoogleLogin")
);
const ReportAnIssue = lazy(() => import("./containers/ReportAnIssue.jsx"));
const ContactSupport = lazy(() => import("./containers/ContactSupport.jsx"));
const NewFeature = lazy(() => import("./containers/NewFeature.jsx"));
const FeatureThankYou = lazy(() => import("./containers/FeatureThankYou.jsx"));
const FeaturePostMessage = lazy(() =>
  import("./containers/FeaturePostMessage.jsx")
);
const UserAccount = lazy(() => import("./containers/UserAccount.jsx"));
const BuildBoxWorld = lazy(() => import("./containers/BuildBoxWorld.js"));
const ReferralLandingPage = lazy(() =>
  import("./containers/ReferralLandingPage.jsx")
);

const GenAIPrompt = lazy(() => import("./containers/genai/GenAIPrompt.tsx"));
const RenewalThankYou = lazy(() => import("./containers/RenewalThankYou"))
const BB4AlphaPreviewNotes = lazy(() => import("./containers/BB4AlphaPreviewNotes"));
const BB4CheckoutRedirect = lazy(() => import("./containers/BB4CheckoutRedirect"));
const BB2CheckoutRedirect = lazy(() => import("./containers/BB2CheckoutRedirect"));

const UpsellAfterTrial = lazy(() => import("./containers/trialUpsell/UpsellAfterTrial"));

const BB2Campaign = lazy(() => import("./containers/landing/BB2Campaign"));

export default function Routes({ appProps }) {
  const homePage = process.env.REACT_APP_MAIN_HOME_PAGE;
  return (
    <Switch>
      <AppliedRoute
        path={routes.SPLASH}
        exact
        component={homePage === "BBCLASSIC" ? NewClassicStoryGamesHome : NewBB4Home}
        appProps={appProps}
      />
      {/* homePage === "BBCLASSIC" ? <Redirect from="/plans/bb4" to="/plans/classic" /> : <Redirect from="/plans/bb3" to="/plans/bb4" /> */}
      <AppliedRoute
        path={routes.PURCHASE_PLANS}
        component={Plans}
        appProps={appProps}
      />
      <AppliedRoute
        path={`${routes.DOWNLOADS}/:version`}
        exact
        component={Downloads}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.STRIPE_CHECKOUT}
        exact
        component={StripeCheckout}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.STRIPE_CHECKOUT_RETURN}
        exact
        component={StripeCheckoutReturn}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CHECKOUT_STRIPE}
        exact
        component={BillingFormStripe}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CHECKOUT_XSOLLA}
        exact
        component={BillingFormXsolla}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.UPSELL_AFTER_TRIAL}
        exact
        component={UpsellAfterTrial}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.REDIRECT}
        exact
        component={ClientUpgradeRedirect}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CONFIRM_ACCOUNT}
        exact
        component={ConfirmAccount}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.ERROR}
        exact
        component={AuthUI}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.LOADING}
        exact
        component={Loader}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.THANK_YOU}
        exact
        component={ThankYou}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.LOGIN}
        exact
        component={Login}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_REDIRECT}
        exact
        component={ClientLoginRedirect}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_LOGOUT_REDIRECT}
        exact
        component={ClientLogoutRedirect}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_POSTMESSAGE_CONNECTION}
        exact
        component={ClientPostmessageConnection}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_GOOGLE_LOGIN}
        exact
        component={ClientGoogleLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_FACEBOOK_LOGIN}
        exact
        component={ClientFacebookLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_APPLE_LOGIN}
        exact
        component={ClientAppleLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_DEEPLINK_LOGIN}
        exact
        component={ClientDeeplinkLogin}
        appProps={appProps}
      />      
      <AppliedRoute
        path={routes.CLIENT_AWAITING_GOOGLE_LOGIN}
        exact
        component={ClientAwaitingGoogleLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_AWAITING_FACEBOOK_LOGIN}
        exact
        component={ClientAwaitingFacebookLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CLIENT_AWAITING_APPLE_LOGIN}
        exact
        component={ClientAwaitingAppleLogin}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.REPORT_AN_ISSUE}
        exact
        component={ReportAnIssue}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CONTACT_SUPPORT}
        exact
        component={ContactSupport}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.CONTACT_SUPPORT}
        exact
        component={ContactSupport}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.SUBMIT_NEW_FEATURE}
        exact
        component={NewFeature}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.REPORTING_THANK_YOU}
        exact
        component={FeatureThankYou}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.FEATURE_POST_MESSAGE}
        exact
        component={FeaturePostMessage}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.BB4_PRODUCT}
        exact
        component={BB4Product}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.BB3_PRODUCT}
        exact
        component={BB3Product}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.BB2_PRODUCT}
        exact
        component={BB2Product}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.BB2_LANDING}
        exact
        component={BB2Campaign}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.SOUNDBOX_PRODUCT}
        exact
        component={SoundboxProduct}
        appProps={appProps}
      />      
      <AppliedRoute
        path={routes.BuildBoxWorld}
        exact
        component={BuildBoxWorld}
        appProps
      />
      <AppliedRoute
        path={`${routes.USER_ACCOUNT}/:accountPage`}
        exact
        component={UserAccount}
        appProps={appProps}
      />
      <AppliedRoute
        path={`${routes.USER_SUBSCRIPTION}/:subscriptionSubPage`}
        exact
        component={UserAccount}
        appProps={appProps}
      />
      <AppliedRoute
        path={`${routes.USER_AD_MANAGEMENT}/:adManagementSubPage`}
        exact
        component={UserAccount}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.REFERRAL_LANDING_PAGE}
        exact
        component={ReferralLandingPage}
        appProps={appProps}
      />
      <AppliedRoute
        path={routes.RENEWAL_THANK_YOU}
        exact 
        component={RenewalThankYou} 
        appProps={appProps}
      />
      <AppliedRoute path={routes.STORY_GAME} 
        exact 
        component={ () => {
          window.location.href = process.env.REACT_APP_STAGE === "production" ? "https://storygames.buildbox.com/": "https://genai-fe.dev.8cell.com/";
          return null;
        }}
        appProps={appProps}

      />
       <AppliedRoute path={routes.BuildBoxWorldProduct} 
        exact 
        component={ () => {
          window.location.href = process.env.REACT_APP_STAGE === "production" ? "https://buildboxworld.com/": "https://buildboxworld.com/";
          return null;
        }}
        appProps={appProps}

      />
      <AppliedRoute path={routes.STORY_GAME_GENERATE} 
        exact
        component={ GenAIPrompt}
        appProps={appProps}
      />
      <AppliedRoute path={routes.BB4_ALPHA_PREVIEW_NOTES} 
        exact
        component={BB4AlphaPreviewNotes}
        appProps={appProps}
      />
      <AppliedRoute 
        path={routes.BB4_CHECKOUT_REDIRECT} 
        exact 
        component={BB4CheckoutRedirect} 
        appProps={appProps}
      />
      <AppliedRoute 
        path={routes.BB2_CHECKOUT_REDIRECT} 
        exact 
        component={BB2CheckoutRedirect} 
        appProps={appProps}
      />
      <AppliedRoute 
        path={routes.APPLE_PAY_SITE_VERIFICATION} 
        exact 
        component={ () => {
          console.log('apple pay site verification route...');
          window.location.href = '/.well-known/apple-developer-merchantid-domain-association.dat';
          return null;          
        }}
        appProps={appProps}
      />          
      <Redirect from="/account" to="/account/profile" />
      <AppliedRoute component={NotFound} />
    </Switch>
  );
}
